<template>
  <div class="summary-reviews product__container">
    <div class="summary-specifications">
      <h2 class="summary-specifications__title">
        {{ _T("@Specifications") }}
      </h2>
      <Specifications :specifications="getSpecifications()" />
      <ProductLink
        :product="product"
        :tab="'product_specifications'"
        class="go-to"
      >
        <span>{{ _T("@Product all specifications") }}</span>
        <img
          src="@/assets/icons/nav-arrow-down.svg"
          alt="arrow down"
          loading="lazy"
        />
      </ProductLink>
    </div>
    <div class="summary-reviews__container">
      <ReviewsPageHeader :product="product" :is-primary-headline="false" />
      <template v-if="null !== reviews">
        <Reviews :reviews="reviews.items" />
        <ProductLink
          v-if="0 !== reviews.items.length"
          :product="product"
          :tab="'product_reviews'"
          class="summary-reviews__btn-more"
        >
          <span>{{ _T("@Product all reviews") }}</span>
          <img
            src="~~/assets/icons/reviews-arrow.svg"
            alt="arrow"
            loading="lazy"
          />
        </ProductLink>
      </template>
    </div>
    <SeenProductCarousel />
  </div>
</template>

<script setup>
import Specifications from "~/modules/product/ui/components/Specifications.vue";
import { Product } from "~/models/product.model";
import SeenProductCarousel from "~/modules/shared/sliders/productCarousels/SeenProductCarousel.vue";
import ReviewsPageHeader from "~/modules/product/ui/components/ReviewsPageHeader.vue";
import Reviews from "~/modules/product/ui/components/Reviews.vue";
import { Specification } from "~/models/specification.model";

const props = defineProps({
  product: { type: Product, required: true },
  reviews: { type: [Object, null], required: true },
});

const getSpecifications = () => {
  return props.product.specifications.slice(0, 1).map(({ group, items }) => {
    return new Specification({
      group,
      items: items.slice(0, 5),
    });
  });
};
</script>

<style scoped lang="scss">
.summary-reviews {
  border-radius: 16px;

  @include mobile {
    border-radius: 0;
  }

  &__container {
    @include flex-container(column, flex-start);
    gap: 24px;
  }

  &__btn-more {
    cursor: pointer;
    width: 200px;

    position: relative;
    @include flex-container(row, center, center);
    gap: 16px;

    @include font(16, 23, 400);
    letter-spacing: 0.32px;

    border-radius: 36px;
    border: 1px solid #d1d1d1;
    background: white;

    padding: 10px 16px;
    margin: auto;

    &:hover img {
      animation: arrow-move-forward 0.4s ease forwards;
    }
  }
}

.summary-specifications {
  max-width: 830px;
  width: 100%;

  @include flex-container(column, flex-start);
  gap: 16px;

  &__title {
    @include font(24, 28, 700);

    @include mobile {
      @include font(18, 24, 600);
    }
  }
}
</style>
